<mat-card
  appearance="outlined"
  class="bg-primary-lighter"
>
  <mat-card-content class="al-wrapper">
    <mat-spinner
      color="primary"
      [diameter]="50"
    />
    <img
      src="assets/images/logo/home.png"
      alt=""
    />
  </mat-card-content>
</mat-card>
