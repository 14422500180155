import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { GlobalErrorHandler } from '@core/error/global-error-handler';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerLocalesEnum } from '@shared/enums/mat-datepicker-locales.enum';
import { AppStorage } from '@shared/storage/app-storage';
import { AppLangEnum } from '@shared/enums/app-lang.enum';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorIntlService } from '@shared/services/paginator-intl.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { LOCATION_URL } from '@core/consts/location-url';
import { environment } from '@root/environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: MAT_DATE_LOCALE,
      useValue:
        AppStorage.getLanguage() === AppLangEnum.ar
          ? MatDatepickerLocalesEnum.Ar
          : MatDatepickerLocalesEnum.En,
    },
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorIntlService,
    },
    {
      provide: LOCATION_URL,
      useValue: `${environment.apiUrl}/v2/LocationsManagement`,
    },
  ],
})
export class CoreModule {}
