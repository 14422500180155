const svgAssetPath = '../assets/images/svg';
/**
 * @deprecated This const is no longer recommended. use mat icon instead
 */
export const SHARED_ICONS: { name: string; path: string }[] = [
  {
    name: 'view_icon',
    path: `${svgAssetPath}/view_icon.svg`,
  },
  {
    name: 'edit_icon',
    path: `${svgAssetPath}/edit_icon.svg`,
  },
  {
    name: 'delete_icon',
    path: `${svgAssetPath}/delete_icon.svg`,
  },
];
