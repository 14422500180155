<mat-dialog-content>
  <div class="flex flex-col items-center">
    <div class="text-center">
      {{ 'module-loading-error.description' | translate }}
    </div>
    <button
      class="mt-3"
      mat-raised-button
      color="primary"
      (click)="refresh()"
    >
      <span>{{ 'shared.retry' | translate }}</span>
    </button>
  </div>
</mat-dialog-content>
