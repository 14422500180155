import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-view-loader',
  standalone: true,
  imports: [MatProgressSpinnerModule, MatCardModule],
  templateUrl: './view-loader.component.html',
  styleUrls: ['./view-loader.component.scss'],
})
export class ViewLoaderComponent {}
