import { Injectable, OnDestroy, inject } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { startWith, Subject, switchMap, takeUntil } from 'rxjs';

@Injectable()
export class PaginatorIntlService
  extends MatPaginatorIntl
  implements OnDestroy
{
  private translateService = inject(TranslateService);
  destroy$ = new Subject<void>();
  OF_LABEL = 'of';
  private _translationsKeys = [
    'paginator.itemsPerPage',
    'paginator.nextPage',
    'paginator.previousPage',
    'paginator.ofLabel',
  ];
  constructor() {
    super();
    this.translateService.onLangChange
      .pipe(
        startWith(this.translateService.currentLang),
        // eslint-disable-next-line rxjs/no-unsafe-takeuntil
        takeUntil(this.destroy$),
        switchMap(() => this.translateService.get(this._translationsKeys))
      )
      .subscribe((translation) => {
        this._initTranslations(translation);
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _initTranslations(translation: any) {
    this.itemsPerPageLabel = translation['paginator.itemsPerPage'];
    this.nextPageLabel = translation['paginator.nextPage'];
    this.previousPageLabel = translation['paginator.previousPage'];
    this.OF_LABEL = translation['paginator.ofLabel'];
    this.changes.next();
  }

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.OF_LABEL} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.OF_LABEL} ${length}`;
  };
}
