import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { AppRouteService } from '@shared/services/app-route.service';
import { of, switchMap } from 'rxjs';
import { isWindowsEnvironment } from '@shared/utils/is-windows-environment';
import { AuthStoreService } from '@core/services/auth-service/auth-store.service';
import { ROUTE_REDIRECT_URL_PROP } from '@shared/constant';

export const loginGuard = (): CanMatchFn => () => {
  const authStoreService = inject(AuthStoreService);
  const router = inject(Router);
  const redirectUrl = router.getCurrentNavigation()?.extractedUrl?.queryParams[
    ROUTE_REDIRECT_URL_PROP
  ] as string;

  if (isWindowsEnvironment) {
    return of(router.createUrlTree([AppRouteService.unauthorizedPath()]));
  } else {
    return authStoreService
      .isAuthenticated()
      .pipe(
        switchMap((isAuth) =>
          isAuth
            ? of(
                !!redirectUrl
                  ? router.createUrlTree([redirectUrl])
                  : router.createUrlTree([AppRouteService.homePath()])
              )
            : of(true)
        )
      );
  }
};
