import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRouteService } from '@shared/services/app-route.service';
import { map, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppService } from '@core/services/app.service';
import { AuthStoreService } from '@core/services/auth-service/auth-store.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private appService = inject(AppService);
  private router = inject(Router);
  private authStoreService = inject(AuthStoreService);

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    const tokenInfo = this.authStoreService.getTokenInfo();
    const isLoginRequest = request['url']
      .toString()
      .toLowerCase()
      .includes('token');

    if (tokenInfo?.accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${tokenInfo.accessToken}`,
          pragma: 'no-cache',
          'cache-control': 'no-cache',
        },
      });
    } else if (isLoginRequest && request.method === 'GET') {
      request = request.clone({ withCredentials: true });
    }

    this.appService.isLoading.next(true);
    return next
      .handle(request)
      .pipe(
        //eslint-disable-next-line
        catchError((error: any) => {
          this.appService.isLoading.next(false);
          switch (error.status) {
            case HttpStatusCode.Unauthorized:
              this.authStoreService.logout();
              break;
            case HttpStatusCode.Forbidden:
              this.router.navigateByUrl(AppRouteService.forbiddenPath());
              break;
            case HttpStatusCode.BadRequest:
              break;
            case HttpStatusCode.InternalServerError:
              break;
            case HttpStatusCode.HttpVersionNotSupported:
              this.authStoreService.setErrorState({
                state: 'versionNoSupported',
                message: error?.error?.reason,
              });
              break;
            default:
              break;
          }

          return throwError(() => error);
        })
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map<HttpEvent<any>, any>((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.appService.isLoading.next(false);
          }
          return event;
        })
      );
  }
}
