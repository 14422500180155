import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, inject, Injectable } from '@angular/core';
import { SnackBarService } from '@shared/snack-bar/services/snack-bar.service';
import { AuthStoreService } from '@core/services/auth-service/auth-store.service';
import { getBadRequestErrorMessage } from '@core/error/errors.model';
import { ModuleLoadingErrorDialogComponent } from '@root/app/module-loading-error-dialog/module-loading-error-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  private snackBarService = inject(SnackBarService);
  private readonly authStoreService = inject(AuthStoreService);
  private readonly dialog = inject(MatDialog);
  handleError(error: Error | HttpErrorResponse) {
    let errorMessage: string | string[] = '';

    // offline app, network issues, CORS errors, server not found
    if (
      (error instanceof HttpErrorResponse &&
        error.error instanceof ProgressEvent) ||
      !navigator.onLine
    ) {
      errorMessage = 'messages.noInternetConnection';
      console.error(error.message);
      this.snackBarService.error(errorMessage);
      return;
    }
    if (error instanceof HttpErrorResponse) {
      if (
        error.url?.includes('AttachedFiles') ||
        error.url?.includes('DownloadExcel')
      ) {
        return;
      }

      switch (error.status) {
        case HttpStatusCode.BadRequest:
          errorMessage = getBadRequestErrorMessage(error);
          break;
        case HttpStatusCode.Forbidden:
          errorMessage = 'messages.operationNotAllowed';
          break;
        case HttpStatusCode.MethodNotAllowed:
          errorMessage = 'messages.notAllowed';
          break;
        case HttpStatusCode.InternalServerError:
          errorMessage = error.error.reason;
          break;
        case HttpStatusCode.HttpVersionNotSupported:
          this.authStoreService.setErrorState({
            state: 'versionNoSupported',
            message: error?.error?.reason,
          });
          break;
        default:
          break;
      }
      console.error(error.error);
      this.snackBarService.error(errorMessage);
    } else if (
      error instanceof Error &&
      error.message.indexOf('Failed to fetch dynamically imported module') > -1
    ) {
      this.dialog.open(ModuleLoadingErrorDialogComponent, {
        width: '350px',
      });
    } else {
      // Client Error
      console.error(error);
      const errorMessage = !!error.message ? error.message : error.toString();
      //ignore expression error in dev mode
      if (
        !error.message.includes('ExpressionChangedAfterItHasBeenCheckedError')
      ) {
        this.snackBarService.error(['Frontend Error', errorMessage]);
      }
    }
  }
}
