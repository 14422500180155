import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@core/guards/auth.guard';
import { loginGuard } from '@core/guards/login.guard';
import { APP_ROUTES } from '@shared/constant/app-routes';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: APP_ROUTES.errorPage,
        loadComponent: () =>
          import('@root/app/error-page/error-page.component').then(
            (m) => m.ErrorPageComponent
          ),
      },
      {
        path: APP_ROUTES.login,
        canMatch: [loginGuard()],
        loadChildren: () =>
          import('@root/app/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: APP_ROUTES.unauthorized,
        loadComponent: () =>
          import('@root/app/access-denied/access-denied.component').then(
            (c) => c.AccessDeniedComponent
          ),
      },
      {
        path: '',
        canMatch: [authGuard()],
        loadChildren: () =>
          import('./layout.module').then((m) => m.LayoutModule),
      },
      { path: '**', redirectTo: APP_ROUTES.notFound },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
