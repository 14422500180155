import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppStorage } from '@shared/storage/app-storage';
import {
  AppHeadersKeys,
  getClientVersionHeader,
} from '@core/app-headers/app-headers-keys';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let lang: string;
    if (request.headers.has(AppHeadersKeys.acceptLanguage)) {
      lang = request.headers.get(AppHeadersKeys.acceptLanguage) ?? '';
    } else {
      lang = AppStorage.getLanguage();
    }
    const modifiedReq = request.clone({
      headers: request.headers
        .set(AppHeadersKeys.acceptLanguage, lang)
        .set(AppHeadersKeys.clientVersion, getClientVersionHeader()),
    });
    return next.handle(modifiedReq);
  }
}
