import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-module-loading-error-dialog',
  standalone: true,
  imports: [MatDialogModule, TranslateModule, MatButton],
  templateUrl: './module-loading-error-dialog.component.html',
  styleUrl: './module-loading-error-dialog.component.scss',
})
export class ModuleLoadingErrorDialogComponent {
  refresh() {
    window.location.reload();
  }
}
